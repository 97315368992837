import Pagination from '@/components/Pagination/Pagination.vue'
import GraphQlMixin from '@/mixins/graphql.mixin'
import QueryMixin from '@/mixins/query.mixin'

export default {
  props: ['updatedItem'],
  mixins: [GraphQlMixin, QueryMixin],
  components: {
    Pagination
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Settings',
          to: { name: 'settings' },
          exact: true
        },
        {
          text: 'Discovery',
          disabled: true
        }
      ],
      asset: {},
      devices: [],
      devicesCount: 0,
      footerProps: { 'items-per-page-options': [10, 25, 50, 100, 200] },
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      headers: [
        { text: 'In RAMS', value: 'ramsId', sortable: false },
        { text: 'Jamf ID', value: 'udid' },
        { text: 'Name', value: 'name' },
        { text: 'Computer Name', value: 'computerName' },
        { text: 'Last User', value: 'lastUser' },
        { text: 'Serial Number', value: 'serialNumber' },
        { text: 'File Vault Status', value: 'fileVaultStatus' },
        { text: 'File Vault 2 Status', value: 'fileVault2Status' },
        { text: 'Model', value: 'model' },
        { text: 'Model Identifier', value: 'modelIdentifier' },
        { text: 'Operating System', value: 'operatingSystemVersion' },
        { text: 'Managed', value: 'managed' },
        { text: 'MDM Capability', value: 'mDMCapability' },
        { text: 'Last Reported IP Address', value: 'lastReportedIPAddress' },
        { text: 'Last Check In', value: 'lastCheckIn' },
        { text: 'Last Inventory Update', value: 'lastInventoryUpdate' },
        { text: 'Device Site', value: 'deviceSite' },
        { text: 'MAC Address', value: 'macAddress' },
        { text: 'EFI Password', value: 'eFIPassword' }
      ],
      search: '',
      debouncing: false,
      debounceTimeout: null
    }
  },
  computed: {
    totalPage() {
      return this.options.itemsPerPage === -1 || this.devicesCount === 0
        ? 1
        : Math.ceil(this.devicesCount / this.options.itemsPerPage)
    }
  },
  watch: {
    options: {
      handler() {
        this.$apollo.queries.jamfComputers.refetch()
      },
      deep: true
    },
    search() {
      this.$apollo.queries.jamfComputers.refetch()
    },
    redirect() {}
  },
  apollo: {
    jamfComputers: {
      query() {
        const fields = this.getFieldsGql('read', 'JamfComputer', [
          'ramsId',
          'udid',
          'name',
          'computerName',
          'lastUser',
          'serialNumber',
          'fileVaultStatus',
          'fileVault2Status',
          'model',
          'modelIdentifier',
          'operatingSystemVersion',
          'managed',
          'mDMCapability',
          'lastReportedIPAddress',
          'lastCheckIn',
          'lastInventoryUpdate',
          'deviceSite',
          'macAddress',
          'eFIPassword'
        ])
        return this.$gql`
          query SearchJamfComputers(
            $q: String
            $take: Int
            $skip: Int
            $orderBy: [OrderBy]
          ) {
            jamfComputers(search: $q, take: $take, skip: $skip, orderBy: $orderBy) {
              items {
                ${fields}
              }
              totalCount
            }
          }
        `
      },
      variables() {
        return {
          q: this.search,
          take:
            this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
          skip:
            this.options.itemsPerPage === -1
              ? 0
              : this.options.itemsPerPage * (this.options.page - 1),
          orderBy: this.getOrderBy()
        }
      },
      skip() {
        return (
          !_.get(this, 'options.page') ||
          this.debouncing ||
          this.loadingQuery ||
          !this.ability.can('read', 'JamfComputer')
        )
      },
      result({ data, error }) {
        if (data && data.jamfComputers) {
          if (this.updatedItem !== undefined) {
            for (let i = 0; i < data.jamfComputers.items.length; i++) {
              if (data.jamfComputers.items[i].id === this.updatedItem.id) {
                data.jamfComputers.items[i] = this.updatedItem
                break
              }
            }
          }
          this.devices = data.jamfComputers.items
          this.devicesCount = data.jamfComputers.totalCount
        } else if (error) {
          this.graphQLOnError(
            `Failed to get list of Jamf devices. ${error.toString()}`
          )
        }
      }
    }
  },
  methods: {
    registerDeviceToRams(device) {
      this.asset = {
        name: device.hostname ? device.hostname.trim() : null,
        status: null,
        serialNumber:
          device.serialNumber && device.serialNumber !== 'UNKNOWN'
            ? device.serialNumber.trim()
            : null,
        purchaseCost: null,
        purchaseCurrencyCode: null,
        purchaseDate: null,
        warrantyExpires: null,
        // locationId: null,
        assigneeId: null,
        memo: null,
        costCode: null,
        extraFields: [],
        region: device.deviceSite
      }
      this.$apollo
        .query({
          // Query
          query: this.$gql`
              query GetModel($name: [String]) {
                models(where: [{ path: "modelNumber", value: $name }]) {
                  items {
                    id
                    name
                    assetType
                    manufacturer {
                      id
                      name
                    }
                  }
                }
              }
            `,
          variables: { name: device.systemProductName }
        })
        .then(({ data }) => {
          if (data.models.items.length > 0) {
            this.asset.model = data.models.items[0]
            this.asset.manufacturer = data.models.items[0].manufacturer
          }
          this.$apollo
            .query({
              query: this.$gql`
                query GetAssignee($samAccountName: [String]) {
                  intraAccounts(where: [{ path: "samAccountName", value: $samAccountName }, { path: "deletedExternally", value: "false" }]) {
                    items {
                      id
                      name
                    }
                  }
                }
              `,
              variables: { samAccountName: device.username }
            })
            .then(({ data }) => {
              if (data.intraAccounts.items.length > 0) {
                this.asset.assignee = data.intraAccounts.items[0]
              }
              this.$router.push({
                name: 'create-asset',
                params: { type: 'computers', discoveryDevice: this.asset }
              })
            })
        })
    }
  },
  created() {
    if (!this.ability.can('read', 'JamfComputer')) {
      this.$router.push('/')
    }
  }
}
